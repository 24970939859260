import React from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import Layout from '../components/Layout'
import Container from '../components/Container'
import SEO from '../components/SEO'
import config from '../utils/siteConfig'

import { primary, Zone, Button, LargeP, PageHeader } from '../elements'

const OurCulturePage = ({ data }) => {
  const postNode = {
    title: `Our Culture - ${config.siteTitle}`,
  }

  return (
    <Layout>
      <Helmet>
        <title>{`Our Culture - ${config.siteTitle}`}</title>
      </Helmet>
      <SEO postNode={postNode} pagePath="contact" customTitle />

      <Zone modifiers={['blank', 'center', 'short']} hero>
        <div className="zone-content">
          <h2>Brand is Culture</h2>
          <h3>
            At D&D, business is not just the promises we make to our customers,
            it is the promise we make to ourselves.
          </h3>
          <p>
            When we promise to put people first, we mean it in a big way. We
            believe that relationship is everything so, if our commitment to
            people including our internal team is strong, our commitment from
            them will be even stronger and make our business better. Because we
            are bound by principles like transparency, innovative leadership and
            empowerment, our teams take pride in the work we do, and our
            customers and partners will have peace of mind knowing that our
            culture is a positive force driving success for everyone.
          </p>
          <Button to="/careers">Become Part of the Team</Button>
        </div>
      </Zone>

      <Container>
        <div>
          <PageHeader>Driving Dependable Service</PageHeader>
          <LargeP>
            This could be a brief but good lead into the culture overview.
          </LargeP>
          <p>
            An overview of the culture at D&D. Don't make this too long but
            should tie everything together under the values, mission and Driving
            Dependable Service.
          </p>
          <h5>Our Mission</h5>
          <LargeP>
            With personalized attention to every aspect of business, we promise
            the service, safety, dependability and hard work needed to exceed
            your expectations.
          </LargeP>
          <h5>Our Vision</h5>
          <LargeP>
            With a tenacious commitment to hard work, transparency and vision,
            we promise honest collaboration and peace of mind.
          </LargeP>
          <h5>Safety First</h5>
          <LargeP>
            Above all we understand that being dependable means putting safety
            first.
          </LargeP>
        </div>
        <div>
          <h5>Why D&D?</h5>
          <ValueTabs>
            <div label="01">
              <ValueH>People First</ValueH>
              <ValueP>
                We believe that connection and relationship is more important
                than the bottom line. If our priority is people, there will
                always be profit.
              </ValueP>
            </div>
            <div label="02">
              <ValueH>Innovative Leadership</ValueH>
              <ValueP>
                Our philosophy drives us forward in technology, process and
                approach. We are cutting edge.
              </ValueP>
            </div>
            <div label="03">
              <ValueH>Peace of Mind</ValueH>
              <ValueP>
                OWe feel a responsibility to help make everyone's job easier and
                more secure. We have your back.
              </ValueP>
            </div>
            <div label="04">
              <ValueH>Perspective</ValueH>
              <ValueP>
                We believe in an approach that is empathetic, proactive and
                flexible. We keep our eye on the big picture and everyone's best
                interest at heart.
              </ValueP>
            </div>
            <div label="05">
              <ValueH>Empowerment</ValueH>
              <ValueP>
                We believe that respect and trust in who we work with and who we
                work for, will pay off in the long run. Inspiring human
                potential is everything.
              </ValueP>
            </div>
          </ValueTabs>
        </div>
      </Container>
    </Layout>
  )
}

export default OurCulturePage

const ValueH = styled.h3`
  color: ${primary};
  margin-bottom: 0.5rem;
`
const ValueP = styled(LargeP)`
  margin-bottom: 3rem;
`

const ValueTabs = styled.div``
